import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import pl from 'date-fns/locale/pl';
import { setHours,  setMinutes} from 'date-fns';
import DatePicker, { registerLocale }  from "react-datepicker";
import { useTranslation } from 'react-i18next';
import styles from "@Pages/EventForms/EventCreateEdit.module.sass";

const DatePickerComponent = ({startDate, setStartDate, endDate, setEndDate, error}) => {
	const { t } = useTranslation();
	registerLocale('pl', pl);
	const currentDate = new Date();

	const [ minTimeStart, setMinTimeStart ] = useState({
		hours: currentDate.getHours(),
		minutes: currentDate.getMinutes(),
	});

	const [ minTimeEnd, setMinTimeEnd ] = useState({
		hours: currentDate.getHours(),
		minutes: currentDate.getMinutes(),
	});

	useEffect(() => {
		if (
			new Date().getDate() === startDate.getDate() &&
			new Date().getMonth() === startDate.getMonth() && 
			new Date().getFullYear() === startDate.getFullYear()
		) {
			setMinTimeStart({
				hours: currentDate.getHours(),
				minutes: currentDate.getMinutes(),
			});
		} else {
			setMinTimeStart({
				hours: 0,
				minutes: 0,
			});
		}
	}, [startDate]);

	useEffect(() => {
		if (
			new Date().getDate() === endDate.getDate() &&
			new Date().getMonth() === endDate.getMonth() && 
			new Date().getFullYear() === endDate.getFullYear()
		) {
			setMinTimeEnd({
				hours: currentDate.getHours(),
				minutes: currentDate.getMinutes(),
			});
		} else {
			setMinTimeEnd({
				hours: 0,
				minutes: 0,
			});
		}
	}, [endDate]);

	return (
		<>	
			<div>
				<p className={styles["form__subtitle"]}>{t('events.create.form.datePickerLabel')}</p>
				<div className={styles["form__data-pickers-container"]}>
					<div className={styles["form__data-picker-container"]}>
						<span className={styles["form__data-picker-label"]}>{t('events.create.form.datePickerStart')}</span>
						<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect timeFormat='p' 
							shouldCloseOnSelect
							timeIntervals={15}
							dateFormat='dd LLLL yyyy, HH:mm'
							locale='pl'
							minDate={new Date()}
							minTime={setHours(setMinutes(new Date(), minTimeStart.minutes), minTimeStart.hours)}
							maxTime={setHours(setMinutes(new Date(), 59), 23)}
							popperClassName={styles["form__datapicker-pooper"]}
							className={styles["form__datapicker-input"]}
						/>
					</div>	
					<div className={styles["form__data-picker-container"]}>
						<span className={styles["form__data-picker-label"]}>{t('events.create.form.datePickerEnd')}</span>
						<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} showTimeSelect timeFormat='p' 
							shouldCloseOnSelect
							timeIntervals={15}
							dateFormat='dd LLLL yyyy, HH:mm'
							locale='pl'
							minDate={new Date()}
							minTime={setHours(setMinutes(new Date(), minTimeEnd.minutes), minTimeEnd.hours)}
							maxTime={setHours(setMinutes(new Date(), 59), 23)}
							popperClassName={styles["form__datapicker-pooper"]}
							className={styles["form__datapicker-input"]}

						/>
					</div>
				</div>
				<p className={styles["form__datapicker-caption"]}>{t('events.create.form.datePickerCaption')}</p>
				{error && <div>{error.message}</div>}
			</div>
		</>

	);
};

DatePickerComponent.propTypes = {
	startDate: PropTypes.date, 
	setStartDate: PropTypes.func, 
	endDate: PropTypes.date, 
	setEndDate: PropTypes.func, 
	error: PropTypes.func,
};

export default DatePickerComponent;