const SecurityGroupsPermissions = {
	"canCreateTranscription": ["USER", "EXPORT_REVIEWER", "SUPER_ADMIN"],
	"canAccessProjectsDashboard": ["SUPER_ADMIN"],
	"canChangeProjectLimit": ["SUPER_ADMIN"],
	"canAccessUsersDashboard": ["SUPER_ADMIN"],
	"canTrainOCR": ["SUPER_ADMIN"],
	"canManageTranscriptionExportToDLibra": ["SUPER_ADMIN", "EXPORT_REVIEWER"],
	"canCreateEvents": ["SUPER_ADMIN", "EXPORT_REVIEWER"],
};

export const CheckSecurityGroupPermission = (permission, securityGroup) => {
	return SecurityGroupsPermissions[permission].includes(securityGroup);
};
