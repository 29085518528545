import React, {useEffect, useState} from 'react';
import api from "@/services/api";
import url from "@/router/urls";

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from "@/store/actions/app.actions";
import { alertActions } from "@/store/actions/alert.actions";
import { reverse } from 'named-urls';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import Error403 from "@Pages/Error/Error403";
import Button from "@Elements/Button/Button";
import Alert from "@Elements/Alert/Alert";
import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import Delete from  '@Assets/icons/delete.svg';
import TranscriptionsItem from '@Elements/Transcriptions/TranscriptionsItem';
import Pagination from "@Components/Pagination/Pagination";
import Search from "@Pages/EventTranscriptions/parts/Search.js";
import styles from "@Pages/EventTranscriptions/EventTranscriptions.module.sass";

const EventTranscriptions = () => {
	const [eventTranscriptions, setEventTranscriptions] = useState([]);
	const [listTranscriptions, setListTranscriptions] = useState([]);
	const [activePage, setAcitvePage] = useState(1);
	const [event, setEvent] = useState('');
	const [haveAcess, setHaveAccess] = useState(false);

	const { id } = useParams();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const userEmail = useSelector(state => state.auth.credentials.email);
	const securityGroup = useSelector((state) => state.auth.credentials.securityGroup);
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label: t('events.allEvents') , id: 2, link: url.events },
		{ label: event ? event.name : t('events.event'), id: 3, link: reverse(`${url.event}`, { id: id }) },
		{ label: t('events.transcriptions.projects'), id: 4 },
	];

	const onChangeCheckbox = (idTranscription, e) => {
		if(!e.target.checked) {
			deleteTranscription(idTranscription);
		} else {
			addTranscriptionToEvent(idTranscription);
		}
	};

	const addTranscriptionToEvent = async (idTranscription) => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.put(`/events/${id}/transcriptions`, [idTranscription]);

			if (res.status === 200) {
				getEventTranscriptions(id);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.transcriptions.warnings.addTranscription'),
				close: true,
			}));
		}
	};

	const deleteTranscription = async (idTranscription) => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.delete(`/events/${id}/transcriptions`, {data: [idTranscription]} );

			if (res.status === 200) {
				getEventTranscriptions(id);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.transcriptions.warnings.deleteTranscription'),
				close: true,
			}));
		}
	};

	const getEvent = async () => {
		try {
			const res = await api.get(`/events/${id}`);

			if(res.status === 200) {
				setEvent(res.data);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.transcriptions.warnings.getEvent'),
				close: true,
			}));
		}
	};

	const getEventTranscriptions = async (idTranscription) => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events/${idTranscription}/transcriptions?page=1&perpage=100`);

			if (res.status === 200) {
				setEventTranscriptions(res.data);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.transcriptions.warnings.getEventTranscriptions'),
				close: true,
			}));
		}
	};

	const getCheckBoxValue = (idTranscription) => {
		return eventTranscriptions.transcriptions.some(event => event.id === idTranscription);
	};

	useEffect(() => {
		getEvent();
	}, []);
	
	useEffect(() => {
		const admin = CheckSecurityGroupPermission("canCreateEvents", securityGroup);
		if (id && !admin && event && event.organizers) {
			setHaveAccess(event.organizers.some(organizer => userEmail === organizer.email));
		} else {
			setHaveAccess(admin);
		}
		
	}, [securityGroup, event, id, isLoggedIn]);

	useEffect(() => {
		if(id) {
			getEventTranscriptions(id);
		}
	}, [id]);

	if (!haveAcess) return <Error403 />;

	return (
		<>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
			</Container>
			<Container>
				<Alert />
				<div className={ styles["events"] }>
					<div className={ styles["events__header"] }>
						<div className={ styles["events__header__inner"] }>
							<h2>{t('events.transcriptions.header')} ({eventTranscriptions && eventTranscriptions?.transcriptions?.length})</h2>					
						</div>
					</div>
					{eventTranscriptions && eventTranscriptions?.transcriptions?.length > 0 ?
						<div className={ styles["events__transcriptions-added"] }> 
							{eventTranscriptions.transcriptions.map((transcription, index) => {
								return (
									<div key={transcription.id}>
										<div className={ styles["events__transcriptions-added__item"] }>
											<span className={ styles["events__transcriptions-added__title"] }>
												{index + 1}. {transcription.titleLabel} 
											</span>
											<Button className={ styles["events__transcriptions-added__button-delete"] } 
												variant='tertiary' onClick={() => deleteTranscription(transcription.id)}
											>
												<img src={Delete} alt='delete'/>{t('events.transcriptions.buttons.delete')}
											</Button>
										</div>
									</div>
								);
								
							}) }
						</div>
						: 
						(
							<div className={ styles["events__transcriptions-zero"] }>
								<span className={ styles["events__transcriptions-zero__text"] }>{t('events.transcriptions.noProjects')}</span>
							</div>
						)}
				</div>
			</Container>
			<Container>
				<div className={ styles["events__projects"] }>
					<div className={ styles["events__projects__header"] }>
						<h3 className={ styles["events__projects__title"] }>{t('events.transcriptions.subHeader')}</h3>
						<p className={ styles["events__projects__description"] }>
							{t('events.transcriptions.description')}
						</p>
					</div>			
					<Search activePage={activePage} setListTranscriptions={setListTranscriptions}/>					
					<Pagination
						currentPage={listTranscriptions.page}
						pagesNum={listTranscriptions.pages}
						onGoToPage={setAcitvePage}
					>
						<div>
							{(listTranscriptions?.transcriptions?.length > 0) &&
							listTranscriptions.transcriptions.map((item, index) => {
								return (
									<TranscriptionsItem
										isSearchView
										transcription={ item }
										key={ item.analyticInfo.title + index.toString() }
										index={index + 1}
										isEventView
										isEventTranscriptionView
										isAddedToEvent={getCheckBoxValue(item.id)}
										onChangeCheckbox={onChangeCheckbox}
									/>
								);
							})}
						</div>
					</Pagination>
				</div>
			</Container>
		</>
	);
};

EventTranscriptions.propTypes = {};

export default EventTranscriptions;